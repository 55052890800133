import { Container, Stack, Button, Loader, Group, Text } from "@mantine/core";
import GatewayHeader from "./GatewayWrapper";
import { Link } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { InlineWidget } from "react-calendly";


export default function BookIntro() {
  const [user, loadingUser, _userError] = useAuthState(getAuth());

  if(loadingUser) return <Loader />;

  return <GatewayHeader step={5}>
    <Container w="100%">
      <Stack>
        <Group justify="center">
          <Text>Use the calendar below to book an introduction tour of SGC Wandsworth.</Text>
          <Text>If you don&apos;t need an intro or are ready to reserve a golf session now then continue to the <Link to="/"><Button>Members App</Button></Link></Text>
          
        </Group>
        <InlineWidget
          url="https://calendly.com/hello-sgcwandsworth/intro-to-sgc-wandsworth-common-1"
          prefill={{ email: user?.email ? user.email : undefined, name: user?.displayName ? user?.displayName : undefined }}
        />
      </Stack>
    </Container>
  </GatewayHeader>
}
