
import { Company } from "./company";
import FirestoreModel from "../FirestoreModel"
import { DateTime } from "luxon";
import { Bay } from "./bay";
import { Customer } from "./customer";
import { nowLiteral } from "./periods";
import { Slot } from "./slot";


export class Location extends FirestoreModel<Location>() {

  static id = "royal_victoria"
  static locationName = "Royal Victoria Building"
  static tz = "Europe/London"
  static address = "Unit 10 & 11, Royal Victoria Patriotic Building, SW18 3SX"
  static currency = "GBP";

  static openingMinute = 420;
  static closingMinute = 1290;

  static primeTimeMorningEnd = 9;
  static primeTimeAfternoonStart = 12;
  static primeTimeAfternoonEnd = 14;
  static primeTimeEveningStart = 17;

  static collectionPath = () => [...Company.refPath(), "locations"];
  constructor(snapshot: any) {
    super(snapshot);
  }

  static isOpen(dt: DateTime) {
    const m = (dt.hour * 60) + dt.minute;
    return m >= Location.openingMinute && m < Location.closingMinute;
  }

  static inPrimeTime(dt: DateTime) {
    if (dt.weekday === 6 || dt.weekday === 7) return true;
    if (dt.hour < Location.primeTimeMorningEnd) return true;
    if (dt.hour >= Location.primeTimeAfternoonStart && dt.hour < Location.primeTimeAfternoonEnd) return true;
    if (dt.hour >= Location.primeTimeEveningStart) return true;
    return false;
  }

  static timeAvailable(startTime: DateTime, bays: Bay[], slotByBay: { [bayId: string]: Slot }, customer: Customer) {
    let best = 0;
    for(const bay of bays) {
      for(let i = 30; i <= 120; i += 30) {
        const time = startTime.plus({minutes: i - 30});
        if(slotByBay[bay.id]?.isTimeTaken(time)) break;
        if(time < nowLiteral(Location.tz)) break;
        if(!Location.isOpen(time)) break;
        if(!customer.primeTime && Location.inPrimeTime(time)) break;
        if(i >= best) best = i;
      }
    }
    return best;
  }

  static baysAvailable(time: DateTime, bays: Bay[], slotByBay: { [bayId: string]: Slot }) {
    let bayCount = 0;
    for(const bay of bays) {
      if(!slotByBay[bay.id]?.isTimeTaken(time)) bayCount += 1;
    }
    return bayCount;
  }

  static bayForTime(time: DateTime, duration: number, bays: Bay[], slotByBay: { [bayId: string]: Slot }) {

    const checkBay = (bay: Bay) => {
      for(let i = 0; i < duration; i += 30) {
        if(slotByBay[bay.id]?.isTimeTaken(time.plus({minutes: i}))) return false;
      }
      return true;
    }

    for(const bay of bays) {
      if(checkBay(bay)) return bay;
    }

    return null;
  }
}