import { Button, Chip, Stack, Group, Text } from "@mantine/core"
import { getFunctions } from "firebase/functions";
import { DateTime } from "luxon"
import { useContext, useEffect, useState } from "react";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { CustomerContext, ReservationsContext } from "../customerContext";
import { notifications } from '@mantine/notifications';
import { Location } from "../shared/location";
import ConfirmedReservation from "./ConfirmedReservation";

export default function ReserveModal({ time, timeAvailable, close }: { time: DateTime, timeAvailable: number, close: ()=>void }) {

  const functions = getFunctions();

  const customer = useContext(CustomerContext);

  const [duration, setDuration] = useState<number | null>(null);

  const [createReservationResult, setCreateReservationResult] = useState<{bayName: string} | null>(null);

  const [createReservationCall, loading, createReservationError] = useHttpsCallable<
    {duration: number, start: Date, locationId: string, overage: number}, {bayName: string}
  >(functions, 'createReservationCall')

  let overage = 0, overageMinutes = 0;

  const makeReservation = async () => {
    if(!duration) return;
    const result = await createReservationCall({
      duration: duration,
      start: time.toJSDate(),
      locationId: Location.id,
      overage,
    })
    if(!result) return;
    setCreateReservationResult(result.data);
  }

  useEffect(() => {
    if (createReservationError) {
      notifications.show({

        title: 'Could not create reservation',
        message: "Sorry this time is no longer available. Please pick a different time",
        withBorder: true,
        color: "red",
      });
    }
  }, [createReservationError])

  const reservations = useContext(ReservationsContext);

  if (createReservationResult) return <ConfirmedReservation time={time} duration={duration!} bayName={createReservationResult.bayName} close={close} />

  const chips = [];
  for (let i = 30; i <= 120; i += 30) {
    chips.push(<Chip key={i} value={i.toString()} disabled={i > timeAvailable}>
      {i} Minutes
    </Chip>)
  }

  if (duration) {
    overageMinutes = customer.overageForReservation(time, duration, reservations);
    overage = (customer.perAdditionalSlot * (overageMinutes / 30))
  }

  return <Stack>
    {time.toFormat("EEE LLL dd @ HH:mm")}

    <Chip.Group multiple={false} value={duration?.toString()} onChange={v => setDuration(parseInt(v))}>
      <Group>
        {chips}
      </Group>
    </Chip.Group>


    {overage ? <Text>Additional {overageMinutes} minutes will cost £{overage.toFixed(2)}</Text> : null}

    <Button onClick={makeReservation} disabled={!duration} loading={loading}>Make Reservation</Button>

  </Stack>
}