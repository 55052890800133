
import FirestoreModel from "../FirestoreModel"

export class Company extends FirestoreModel<Company>() {

  static id = "sgc_wandsworth";
  static companyName = "SGC Wandsworth";
  static errorMsg = "Sorry there was an error please refresh and try again, if the error persists contact us at hello@sgcwandsworth.com";

  static collectionPath = () => ["companies"];
  static refPath() {
    return [...Company.collectionPath(), Company.id];
  }
  subscriptionId: string;

  constructor(snapshot: any) {
    super(snapshot);
    const data = snapshot.data();
    this.subscriptionId = data.subscriptionId;
  }
}
