import { Accordion } from "@mantine/core"

const faqs = [
  {
    value: "How does trackman work?",
    description: "Our golf simulators use a combination of high-speed cameras, radar, and sensors to track the golf ball and your swing. The data is then processed by the simulator's software, which displays a realistic 3D environment of the golf course on a large screen in front of you. The system provides instant feedback on your shots, including ball speed, trajectory, and spin. https://www.youtube.com/watch?v=GLtB2ZgPfDo",
  },
  {
    value: "How far can I book in advance?",
    description: "You can book your session up to 14 days in advance. We recommend booking as early as possible, especially during peak times, to secure your preferred time slot. This ensures you have the best chance of getting the time that works best for you. ",
  },
  {
    value: "Is there parking available?",
    description: "Yes, we have parking available on-site for our customers. If you're traveling by public transport, our venue is also conveniently located near major bus routes and tube stations.",
  },
  {
    value: "What should I wear?",
    description: "While we don't have a strict dress code, we recommend wearing comfortable golf attire, similar to what you would wear on an outdoor course. Athletic shoes with a good grip are ideal. Please avoid wearing golf shoes with spikes as they can damage the simulator surface.",
  },
  {
    value: "Can I bring my own golf balls to use in the simulator?",
    description: "To ensure the best experience and protect our equipment, we kindly ask that you do not bring your own golf balls. Using personal balls, especially those with dirt, logos, or markings, can damage the simulator screen and affect the accuracy of the system. We provide high-quality Vice golf balls for all players to use, which are specifically chosen to work perfectly with our simulators. These balls are replaced regularly to ensure optimal performance and accuracy during your session.",
  },
  {
    value: "Do you offer lessons or coaching?",
    description: "Yes, we have professional golf instructors available who can provide lessons and coaching to help you improve your game. Whether you're a beginner looking to learn the basics or an experienced player aiming to refine your skills, our instructors can tailor sessions to meet your goals. Get in touch via email (hello@sgcwandsworth.com) or Whatsapp Jacob on +447477112412  to find out more about pricing and availability.",
  },
  {
    value: "How long does it take to play a round?",
    description: "A full 18-hole round typically takes about an hour per player, depending on the pace of play and the skill level. For example, if you're playing with a group of two, you might expect to finish in around two hours. However, you can always opt to play fewer holes or use the simulator for practice sessions instead.",
  },
  {
    value: "How many simulator bays do you have?",
    description: "We currently have one state-of-the-art simulator bay available for our members to enjoy, offering a focused and personalised experience. We're committed to providing the best service possible, and if we see strong demand and continued commitment from our community, we plan to expand by adding two more bays. Your support will help us grow and enhance our facility!",
  },
]

export default function Help() {
  const items = faqs.map((item) => (
    <Accordion.Item key={item.value} value={item.value}>
      <Accordion.Control>{item.value}</Accordion.Control>
      <Accordion.Panel>{item.description}</Accordion.Panel>
    </Accordion.Item>
  ));

  return <Accordion defaultValue="Apples">{items}</Accordion>
}