import { Avatar, Burger, Group, Image, Text, Title } from "@mantine/core";
import { getAuth } from "firebase/auth";

export default function AppHeader({ opened, toggle }: { opened: boolean, toggle: () => void }) {

  const auth = getAuth();

  return <Group px="md" justify="space-between">
    <Burger
      opened={opened}
      onClick={toggle}
      hiddenFrom="sm"
      size="sm"
    />
    <Image src="/logo.svg" h="80" w="auto" pl="10" />
    <Title visibleFrom="md">SGC Wandsworth Common Members</Title>
    <Group pr="10" visibleFrom="md" >
      <Avatar src={auth.currentUser?.photoURL} radius="xl" />

      <div style={{ flex: 1 }}>
        <Text size="sm" fw={500}>
          {auth.currentUser?.displayName}
        </Text>
        <Text c="dimmed" size="xs">
          {auth.currentUser?.email}
        </Text>
      </div>
    </Group>
  </Group>
}