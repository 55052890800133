
import { Bay } from "./bay";
import FirestoreModel from "../FirestoreModel";
import { DateTime } from "luxon";


export class Slot extends FirestoreModel<Slot>() {

  static timeFormat = "yyyy-LL-dd'T'HH:mm:ss";

  static collectionPath = (location: string, bay: string) => [...Bay.refPath(bay, location), "slots"];

  time: DateTime | null;
  date: DateTime | null;
  private fields: any;


  constructor(snapshot: any) {
    super(snapshot);
    const data = snapshot.data();
    this.time = data.time ? DateTime.fromMillis(data.time.toMillis()).toUTC() : null;
    this.date = data.date ? DateTime.fromMillis(data.date.toMillis()).toUTC() : null;
    this.fields = data;
  }

  isTimeTaken(time: DateTime<true>) {
    return !!this.fields[time.toFormat(Slot.timeFormat)]
  }
}