import { Center, Stack, Title, Image, Group, Avatar, Text, Button } from "@mantine/core"
import SignUpStepper from "./SignUpStepper";
import { ReactNode } from "react";
import { getAuth, signOut } from "firebase/auth";

export default function GatewayWrapper({ children, step }: { children: ReactNode, step: number | null }) {

  const auth = getAuth();


  return <Stack m="lg">
    <Group justify="space-between">
      <Center>
        <Image src="/logo.svg" h="80" w="auto" pl="10" />
        <Title order={1}>SGC Wandsworth Common</Title>
      </Center>
      {auth.currentUser ? <Group pr="10" visibleFrom="md">
        <Avatar src={auth.currentUser?.photoURL} radius="xl" />
        <div style={{ flex: 1 }}>
          <Text size="sm" fw={500}>
            {auth.currentUser?.displayName}
          </Text>
          <Text c="dimmed" size="xs">
            {auth.currentUser?.email}
          </Text>
        </div>
        <Button onClick={() => signOut(auth)}>Logout</Button>
      </Group> : null}
    </Group>


    {step ? <SignUpStepper step={step} /> : null}
    {children}
  </Stack>
}