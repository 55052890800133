import { doc, DocumentData, getFirestore, QueryDocumentSnapshot, WithFieldValue, collection, DocumentReference } from "firebase/firestore";

// FRONTEND FRONTEND FRONTEND
function FirestoreModel<T>() {
  class FirestoreModel {
    public id: string;
    public ref : DocumentReference;
    

    public static collectionPath(_location?: string, _bay?: string): string[] {
      throw new Error("Must override");
    }
    public static refPath(id: string, location?: string, bay?: string): string[] {
      return [...this.collectionPath(location, bay), id];
    }

    public static ref(id: string, location?: string, bay?: string) {
      const db = getFirestore()
      return doc(db, this.refPath(id, location, bay).join("/")).withConverter<T>(this);
    }
    public static collection(location?: string, bay?: string) {
      const db = getFirestore()
      return collection(db, this.collectionPath(location, bay).join("/")).withConverter<T>(this);
    }


    static fromFirestore<T>( snapshot: QueryDocumentSnapshot): T {
      return new this(snapshot) as T;
    }

    static toFirestore<T extends FirestoreModel>(data: WithFieldValue<T>): DocumentData {
      return data;
    }

    constructor(snapshot: QueryDocumentSnapshot) {
      this.id = snapshot.id;
      this.ref = snapshot.ref;
    }

    // public static refPath()
  }
  return FirestoreModel;
}
export default FirestoreModel;