
import { Company } from "./company";
import FirestoreModel from "../FirestoreModel";
import {DateTime} from "luxon";

export class Reservation extends FirestoreModel<Reservation>() {

  static collectionPath = () => [...Company.refPath(), "reservations"];
  duration: number;
  start: DateTime<true>;
  usedMinutes: number;
  appliedCredits: number;
  purchasedMinutes: number;
  purchasedValue: number | undefined;
  createdAt: DateTime;
  cancelledAt: DateTime | null | undefined;
  bay: any;
  bayName: string | null | undefined;
  location: any;
  refunded: boolean;
  customer: any;
  invoiceItem: string | null | undefined;

  constructor(snapshot: any) {
    super(snapshot);
    const data = snapshot.data();
    this.duration = data.duration;
    const start = DateTime.fromMillis(data.start.toMillis()).toUTC();
    if(!start.isValid) {
      throw new Error(`invalid start date ${data.start}`);
    }
    this.start = start;
    this.createdAt = DateTime.fromMillis(data.createdAt.toMillis());
    this.usedMinutes = data.usedMinutes;
    this.appliedCredits = data.appliedCredits;
    this.purchasedMinutes = data.purchasedMinutes;
    this.purchasedValue = data.purchasedValue;
    this.cancelledAt = data.cancelledAt ? DateTime.fromMillis(data.cancelledAt.toMillis()) : null;
    this.bay = data.bay
    this.location = data.location
    this.refunded = !!(data.refunded)
    this.customer = data.customer
    this.invoiceItem = data.invoiceItem;
    this.bayName = data.bayName ? data.bayName : null;
  }

  get end() {
    return this.start.plus({minutes: this.duration});
  }

  get locationId() {
    return this.location.split('/').pop() as string;
  }
  get bayId() {
    return this.bay.split('/').pop() as string;
  }
  get customerId() {
    const customerId = this.customer.split('/').pop() as string;
    if(!customerId) {
      throw new Error("Reservation is missing customer");
    }
    return customerId;
  }
}
