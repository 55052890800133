import { Button, Stack, Text } from "@mantine/core";
import { IconExternalLink } from "@tabler/icons-react";
import { getAuth, signOut } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { useNavigate } from "react-router-dom";

export default function Account() {

  const functions = getFunctions();
  const auth = getAuth();
  const [getLink, loadingLink, _errorWithLink] = useHttpsCallable(functions, "stripePortalLink");
  const navigate = useNavigate();

  return <Stack align="flex-start">
    <Text>Click below to manage your subscription, billing and account details in our billing portal.</Text>
    <Text>To cancel your subscription please contact hello@sgcwandsworth.com</Text>
    <Button rightSection={<IconExternalLink />}
      loading={loadingLink}
      disabled={loadingLink}
      onClick={async () => {
        const result = await getLink({ returnUrl: window.location.href });
        window.location.replace(result!.data as string);
      }} >
      Manage Subscription and Billing
    </Button>


    <Button onClick={async () => {
      await signOut(auth);
      navigate('/');
    }} >Logout</Button>
  </Stack>;
}