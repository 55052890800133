import { Button } from "@mantine/core"
import { useHttpsCallable } from "react-firebase-hooks/functions"
import { Reservation } from "../shared/reservation";
import { getFunctions } from "firebase/functions";
import { nowLiteral } from "../shared/periods";
import { Location } from "../shared/location";

export default function CancelReservation({ reservation }: { reservation: Reservation }) {

  const functions = getFunctions();

  const [cancelReservationCall, loading, _error] = useHttpsCallable(functions, 'cancelReservationCall')

  return <Button
    onClick={() => cancelReservationCall({ reservationId: reservation.id })}
    disabled={!!reservation.cancelledAt || reservation.start < nowLiteral(Location.tz)}
    loading={loading}>
    Cancel
  </Button>
}
