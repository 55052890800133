import { useContext } from "react";
import { UserContext } from "./UserGateway";

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

export default function SignUpFlow() {
  const user = useContext(UserContext);

  return <>
    <stripe-pricing-table
      pricing-table-id={import.meta.env.VITE_STRIPE_pricingTableId}
      publishable-key={import.meta.env.VITE_STRIPE_publishableKey}
      client-reference-id={user.uid}
      customer-email={user.email} />
  </>

}