import { Button, Group, Stack, Table, Title } from "@mantine/core";
import CancelReservation from "./CancelReservation";
import { useContext } from "react";
import { ReservationsContext } from "../customerContext";
import { NavLink } from "react-router-dom";
import { nowLiteral } from "../shared/periods";
import {Location} from "../shared/location";

export default function UpcomingReservations() {

  const now = nowLiteral(Location.tz)
  const reservations = useContext(ReservationsContext).filter(r => r.start > now);
  if (!reservations) return <span>You currently have no reservations</span>

  return <Stack>
    <Group>
      <Title order={2}>Upcoming Reservations</Title>
      <NavLink to="/reservations/all"><Button>See all reservations</Button></NavLink>
    </Group>
    <Table striped highlightOnHover withTableBorder>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Time</Table.Th>
          <Table.Th>Duration</Table.Th>
          <Table.Th>Bay</Table.Th>
          <Table.Th></Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>{reservations.map(r =>
        <Table.Tr key={r.id} td={r.cancelledAt ? "line-through" : ""}>
          <Table.Td>{r.start.toFormat("EEE LLL dd @ HH:mm")}</Table.Td>
          <Table.Td>{r.duration} minutes</Table.Td>
          <Table.Td>{r.bayName || r.bay.id}</Table.Td>
          <Table.Td>
            <CancelReservation reservation={r} />
          </Table.Td>
        </Table.Tr>
      )}</Table.Tbody>
    </Table>

  </Stack>
}