import { Carousel, Embla } from "@mantine/carousel"
import { ActionIcon, Group, Loader, Stack } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { Location } from "../shared/location";
import { IconChevronLeft, IconChevronRight, IconChevronsLeft, IconChevronsRight } from "@tabler/icons-react";
import ReserveSlide from "./ReserveSlide";
import { nowLiteral } from "../shared/periods";
import { useCollection } from "react-firebase-hooks/firestore";
import { query } from "firebase/firestore";
import { Bay } from "../shared/bay";
import { CustomerContext } from "../customerContext";

export default function Reserve() {

  const customer = useContext(CustomerContext);

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    };
  }, []);

  const [baysSS, _loadingBay, _baysError] = useCollection(query(Bay.collection(Location.id)));

  if(_baysError) {
    console.log(_baysError);
  }

  const [embla, setEmbla] = useState<Embla | null>(null);

  if(!baysSS) return <Loader />

  const bays = baysSS.docs.map(b => b.data());

  const todayLiteral = nowLiteral(Location.tz).startOf("day");

  const dates = Array.from(Array(customer.daysInAdvance).keys())
    .map(i => todayLiteral.plus({ days: i }));

  return <Stack>
    <Group justify="space-between">
      <Group>
        <ActionIcon onClick={() => [...Array(7)].map(() => embla?.scrollPrev())} size="xl">
          <IconChevronsLeft />
        </ActionIcon>
        <ActionIcon onClick={() => embla?.scrollPrev()} size="xl">
          <IconChevronLeft />
        </ActionIcon>
      </Group>
      <Group>
        <ActionIcon onClick={() => embla?.scrollNext()} size="xl">
          <IconChevronRight />
        </ActionIcon>

        <ActionIcon onClick={() => [...Array(7)].map(() => embla?.scrollNext())} size="xl">
          <IconChevronsRight />
        </ActionIcon>
      </Group>
    </Group>
    <Carousel
      getEmblaApi={setEmbla}
      slideSize={{ base: '100%', sm: '50%', md: '33.333333%', lg: "25%" }}
      slideGap="md"
      align="start"
      withControls={false}
    >
      {dates.map(date => <ReserveSlide key={date.toSeconds()} date={date} bays={bays}/>)}
    </Carousel>
  </Stack>
}