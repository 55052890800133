import React from 'react';
import ReactDOM from 'react-dom/client';
import '@mantine/core/styles.css';
import '@mantine/carousel/styles.css';
import '@mantine/notifications/styles.css';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import UserGateway from './pre_app/UserGateway';
import { MantineProvider } from '@mantine/core';
import { doc, getFirestore } from 'firebase/firestore';
import { Notifications } from '@mantine/notifications';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Reserve from './reserve/Reserve';
import Account from './Account';
import UpcomingReservations from './my_reservations/UpcomingReservations';
import Help from './Help';
import CheckoutComplete from './pre_app/CheckoutComplete';
import AllReservations from './my_reservations/AllReservations';
import BookIntro from './pre_app/BookIntro';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://117b458795c1c6aa7a93c2e02b1096cd@o4507703606050816.ingest.de.sentry.io/4507703607689296",
  integrations: [],
});

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_apiKey,
  authDomain: import.meta.env.VITE_FIREBASE_authDomain,
  projectId: import.meta.env.VITE_FIREBASE_projectId,
  appId: import.meta.env.VITE_FIREBASE_appId
};

// Initialize Firebase
initializeApp(firebaseConfig);

const db = getFirestore();
export const companyRef = doc(db, "companies", "fairway_club");


const router = createBrowserRouter([
  {
    path: "/",
    element: <UserGateway />,
    children: [
      {
        path: "/",
        element: <Reserve />,
      },
      {
        path: "/account",
        element: <Account />,
      },
      {
        path: "/reservations",
        element: <UpcomingReservations />,
      },
      {
        path: "/reservations/all",
        element: <AllReservations />,
      },
      {
        path: "/help",
        element: <Help />,
      },
    ],
  },
  {
    path: "/checkoutComplete",
    element: <CheckoutComplete />,
  },
  {
    path: "/intro",
    element: <BookIntro />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <MantineProvider>
      <Notifications position="top-right" />
      <RouterProvider router={router} />
    </MantineProvider>
  </React.StrictMode>
);
