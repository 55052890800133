import { AppShell, Stack, NavLink as MantineNavLink } from "@mantine/core";
import InfoBar from "./InfoBar";
import { useDisclosure } from "@mantine/hooks";
import { Outlet, NavLink as RouterNavLink } from "react-router-dom";
import AppHeader from "./AppHeader";
import { IconCalendar, IconHelp, IconPlus, IconUserCircle } from "@tabler/icons-react";
import { CustomerContext, ReservationsContext } from "./customerContext";
import { ReactElement, useContext } from "react";
import { nowLiteral } from "./shared/periods";
import { Location } from "./shared/location";
import { useCollection } from "react-firebase-hooks/firestore";
import { orderBy, query, where } from "firebase/firestore";
import { Reservation } from "./shared/reservation"
import { Customer } from "./shared/customer";

function App() {

  const customer = useContext(CustomerContext);

  const periods = customer.periodsForDateTime(nowLiteral(Location.tz))

  const [reservations, _loadingReservations, _reservationsError] = useCollection(
    query(
      Reservation.collection(),
      orderBy("start"),
      where("start", ">=", periods.current.start.toJSDate()),
      where("customer", "==", Customer.ref(customer.id)),
    )
  )

  const [opened, { toggle }] = useDisclosure();

  const links: [string, string, ReactElement][] = [
    ["Reserve", "/", <IconPlus key={"/"} />],
    ["My Reservations", "/reservations", <IconCalendar key={"/reservations"} />],
    ["My Account", "/account", <IconUserCircle key={"/account"} />],
    ["FAQ's and Support", "/help", <IconHelp key={"/help"} />],
  ]

  return (
    <ReservationsContext.Provider value={reservations?.docs.map(r => r.data()) || []} >
      <AppShell
        header={{ height: 80 }}
        navbar={{
          width: 210,
          breakpoint: 'sm',
          collapsed: { mobile: !opened },
        }}
        padding="md"
      >
        <AppShell.Header>
          <AppHeader opened={opened} toggle={toggle} />
        </AppShell.Header>

        <AppShell.Navbar>
          <Stack align="flex-start" p="20">
            {links.map(l => {
              return <RouterNavLink
                onClick={toggle}
                key={l[1]}
                to={l[1]}
                style={{ textDecoration: 'inherit', color: 'inherit', width: "100%" }}>
                {({ isActive }) => (
                  <MantineNavLink
                    active={isActive}
                    label={l[0]}
                    leftSection={l[2]}
                  />
                )}
              </RouterNavLink>
            })}
            <InfoBar />
          </Stack>
        </AppShell.Navbar>

        <AppShell.Main>
          <Outlet />
        </AppShell.Main>
      </AppShell>
    </ReservationsContext.Provider>
  );
}

export default App;
