import { Container, Stack, Text, Button, Loader, Group } from "@mantine/core";
import GatewayHeader from "./GatewayWrapper";
import { useDocument } from "react-firebase-hooks/firestore";
import { Customer } from "../shared/customer";
import { Link } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";

export default function CheckoutComplete() {

  const [user, loadingUser, _userError] = useAuthState(getAuth());

  const [customerSS, loadingCustomer, _customerError] = useDocument(user ? Customer.ref(user.uid) : null);

  if (loadingUser) {
    return <GatewayHeader step={null}><Loader /></GatewayHeader>
  }

  if (!user) {
    return <GatewayHeader step={null}>
      <Text>Please return to <Link to="/">Members home</Link> to login.</Text>
    </GatewayHeader>
  }


  const customer = customerSS?.data();

  // useEffect(() => {
  //   if(newCustomer && customer) {
  //     window.dataLayer.push({ ecommerce: null });
  //     window.dataLayer.push({
  //       event: "purchase",
  //       ecommerce: {
  //         value: customer.mrr/100,
  //         currency: customer.currency.toUpperCase(),
  //         transaction_id: customer.subscriptionId,
  //         items: [{item_id: customer.productId}]
  //       }
  //     });
  //   }
  // }, [newCustomer, customer]);

  if (!customer || loadingCustomer) {
    return <GatewayHeader step={4}>
      <Loader />
      Proccesing subscription please wait.
    </GatewayHeader>
  }

  return <GatewayHeader step={5}>
    <Container>
      <Stack>
        <Text fw="700">Thank You for Registering!</Text>
        <Text>
          Welcome to the SGC Wandsworth community! We&apos;re thrilled to have you on board and can&apos;t wait for you to experience our state-of-the-art golf simulator. Get ready to elevate your game and enjoy some of the world&apos;s most iconic courses right here in London.
          A member of our team will be in touch shortly to finalise your membership details and answer any questions you might have. We&apos;re excited to help you get started on your golfing journey with us!
          See you on the virtual greens!
        </Text>

        <Text>
          If you want to book your onboarding introduction of SGC Wandsworth now then click below to book an intro. If you are ready to book a golf session then continue to the members app.
        </Text>
        
        <Group>
          <Link to="/intro"><Button>Book an Intro</Button></Link>
          <Link to="/"><Button>Continue to Members App</Button></Link>
        </Group>

        <Text fw="700">The SGC Wandsworth Team</Text>
      </Stack>
    </Container>
  </GatewayHeader>
}