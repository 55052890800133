import { Reservation } from "../shared/reservation";
import { orderBy, query, where } from "firebase/firestore";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import { getAuth } from "firebase/auth";
import { Customer } from "../shared/customer";
import { Button, Loader, Stack, Table } from "@mantine/core";
import { Company } from "../shared/company";
import { NavLink } from "react-router-dom";

export default function AllReservations() {
  const auth = getAuth();

  const [reservations, loadingReservations, reservationsError] = useCollectionOnce(
    query(
      Reservation.collection(),
      orderBy("start"),
      where("customer", "==", Customer.ref(auth.currentUser!.uid)),
    )
  )

  if (loadingReservations) return <Loader />;
  if (reservationsError) return <span>{Company.errorMsg}</span>;
  if(!reservations) return <span>You currently have no reservations</span>

  return <Stack>
    <NavLink to="/reservations"><Button>Back to upcoming reservations</Button></NavLink>
    <Table striped highlightOnHover withTableBorder>
    <Table.Thead>
      <Table.Tr>
        <Table.Th>Time</Table.Th>
        <Table.Th>Duration</Table.Th>
        <Table.Th>Bay</Table.Th>
        <Table.Th>Minutes Used</Table.Th>
        <Table.Th>Credits Used</Table.Th>
        <Table.Th>Minutes Purchased</Table.Th>
        <Table.Th>Refunded</Table.Th>
        <Table.Th>Cancelled</Table.Th>
      </Table.Tr>
    </Table.Thead>
    <Table.Tbody>{reservations.docs.map(s => s.data()).map(r =>
      <Table.Tr key={r.id}>
        <Table.Td td={r.cancelledAt ? "line-through" : ""}>{r.start.toFormat("LLL dd HH:mm")}</Table.Td>
        <Table.Td td={r.cancelledAt ? "line-through" : ""}>{r.duration} minutes</Table.Td>
        <Table.Td td={r.cancelledAt ? "line-through" : ""}>{r.bayName || r.bay.id}</Table.Td>
        <Table.Td td={r.refunded ? "line-through" : ""}>{r.usedMinutes || 0}</Table.Td>
        <Table.Td td={r.refunded ? "line-through" : ""}>{r.appliedCredits || 0}</Table.Td>
        <Table.Td td={r.refunded ? "line-through" : ""} >{r.purchasedMinutes || 0}</Table.Td>
        <Table.Td>{r.refunded ? "Refunded" : ""}</Table.Td>
        <Table.Td>{r.cancelledAt ? "Cancelled" : ""}</Table.Td>
      </Table.Tr>
    )}</Table.Tbody>
  </Table>
  </Stack>
}