import { AddToCalendarButton } from "add-to-calendar-button-react";
import { DateTime } from "luxon";
import {Location} from "../shared/location";
import {Company} from "../shared/company";
import { Alert, Stack, Text, Button, Group } from "@mantine/core";

export default function ConfirmedReservation({time, duration, bayName, close}: {time: DateTime, duration: number, bayName: string, close: ()=>void}) {
    return <Stack>
      <Alert>
        <Text>{time.toFormat("LLL dd @ HH:mm")} for {duration} minutes is booked!</Text>
        This reservation will be at <Text fw={700} span>{bayName}</Text>
      </Alert>
      <Group justify="space-between">
        <AddToCalendarButton
          name={`${Company.companyName} - ${bayName}`}
          description={`${duration} minute reservation at ${Company.companyName} - ${bayName}.`}
          startDate={time.toFormat("yyyy-LL-dd")}
          startTime={time.toFormat("HH:mm")}
          endTime={time.plus({minutes: duration}).toFormat("HH:mm")}
          options={['Apple', 'Google', 'Yahoo', 'iCal']}
          timeZone={Location.tz}
          forceOverlay={true}
          location={Location.address}
        />
        <Button onClick={close}>Done</Button>
      </Group>
      
    </Stack>
}