import { Alert, Center, Stack, Stepper } from "@mantine/core";

export default function SignUpStepper({ step }: { step: number }) {
  return (
    <Stack>
      <Stepper active={step-1} m="lg">
        <Stepper.Step label="First step" description="Create an account" />
        <Stepper.Step label="Decision time" description="Pick a subscription" />
        <Stepper.Step label="Settle up" description="Set up payment" />
        <Stepper.Step label="Introduction" description="Get to know us" />
        <Stepper.Step label="Lets go" description="Make a reservation" />
      </Stepper>
      {step < 3 && <Center>
        <Alert>
          If you would like to visit first then get in touch at <a href= "mailto: hello@sgcwandsworth.com">hello@sgcwandsworth.com</a> and we&apos;ll setup a tour
        </Alert>
      </Center>}
      
    </Stack>
  )
}