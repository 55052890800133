import { getAuth, User } from "firebase/auth";
import FirebaseUIReact from "firebaseui-react";
import { Center, Container, Loader, Text } from "@mantine/core";
import CustomerGateway from "./CustomerGateway";
import { useAuthState } from "react-firebase-hooks/auth";
import GatewayHeader from "./GatewayWrapper";
import { createContext, useContext } from "react";
import historyContext from "./historyContext";

// @ts-expect-error We know we will have a user here so use this to avoid unwrapping all the time.
export const UserContext = createContext<User>(null);

function UserGateway() {
  const auth = getAuth();
  const history = useContext(historyContext);

  const [user, loading, _error] = useAuthState(auth);

  if (loading) return <GatewayHeader step={null}><Center><Loader /></Center></GatewayHeader>;

  if (user) return <UserContext.Provider value={user}>
    <CustomerGateway />
  </UserContext.Provider>;

  const urlParams = new URLSearchParams(window.location.search)

  const config = {
    continueUrl: import.meta.env.VITE_HOST,
    signInOptions: ["emaillink", "google.com", ...(urlParams.get("pwdlogin") ? ["emailpassword"] : [])],
  };

  return <GatewayHeader step={history.location.hash == "#newMember" ? 1 : null}>
    <Container>
      <Text>Both new and returning customers please sign in using any of the methods below.</Text>
      <FirebaseUIReact auth={auth} config={config} />
    </Container>
  </GatewayHeader>;
}

export default UserGateway;
