
import { Company } from "./company";
import FirestoreModel from "../FirestoreModel";
import { DateTime } from "luxon";
import { Reservation } from "./reservation";


type Period = { start: DateTime, end: DateTime };

export class Customer extends FirestoreModel<Customer>() {

  static collectionPath = () => [...Company.refPath(), "customers"];
  subscriptionId: string;
  periodAnchorDay: number;
  monthlyMinutes: number;
  primeTime: boolean;
  perAdditionalSlot: number;
  daysInAdvance: number;
  credits: number;
  stripeId: string;
  userId: string;
  productId: string;
  subscriptionStatus: string;
  name: string;
  phoneNumber: string;
  mrr: number;
  currency: string;


  constructor(snapshot: any) {
    super(snapshot);
    const data = snapshot.data();
    this.subscriptionId = data.subscriptionId;
    this.periodAnchorDay = data.periodAnchorDay;
    this.monthlyMinutes = data.monthlyMinutes;
    this.daysInAdvance = data.daysInAdvance || 14;
    this.primeTime = data.primeTime;
    this.credits = data.credits || 0;
    this.stripeId = data.stripeId;
    this.userId = data.userId;
    this.productId = data.productId;
    this.subscriptionStatus = data.subscriptionStatus
    this.perAdditionalSlot = data.perAdditionalSlot;
    this.name = data.name;
    this.phoneNumber = data.phoneNumber;
    this.mrr = data.mrr;
    this.currency = data.currency;
  }

  isActive() {
    return !["incomplete", "incomplete_expired", "canceled", "paused", "unpaid"].includes(this.subscriptionStatus);
  }

  periodsForDateTime(dt: DateTime): { current: Period, next: Period } {

    const setToAnchorDoM = (dt: DateTime) => {
      return dt.set({ day: Math.min(this.periodAnchorDay, dt.endOf("month").day) });
    };
  
    let periodStart = dt.startOf("month");
    if (dt.day < this.periodAnchorDay) {
      periodStart = periodStart.minus({ months: 1 });
    }
    periodStart = setToAnchorDoM(periodStart);
    const periodEnd = setToAnchorDoM(periodStart.plus({ months: 1 }));
    const nextPeriodStart = setToAnchorDoM(periodEnd.plus({month: 1}));
  
    return { current: { start: periodStart, end: periodEnd }, next: {start: periodEnd, end: nextPeriodStart} };
  }

  minutesRemainingForPeriod(period: Period, reservations: Reservation[]) {
    const used = reservations.filter(d => d.start >= period.start && d.start < period.end && !d.refunded).map(r => r.usedMinutes).reduce((a, b) => a + b, 0);
    return Math.max(0, this.monthlyMinutes - used);
  }

  overageForReservation(date: DateTime, duration: number, reservations: Reservation[]) {
    const periods = this.periodsForDateTime(date);
    return Math.max(0, duration - this.minutesRemainingForPeriod(periods.current, reservations) - this.credits)
  }
}
