import { Progress, Stack } from "@mantine/core"
import { Text } from "@mantine/core";
import { useContext } from "react";
import { CustomerContext, ReservationsContext } from "./customerContext";
import { nowLiteral } from "./shared/periods";
import { Location } from "./shared/location";

export default function InfoBar() {

  const customer = useContext(CustomerContext);

  const periods = customer.periodsForDateTime(nowLiteral(Location.tz));

  const reservations = useContext(ReservationsContext);
  const currentPeriodRemaining = customer.minutesRemainingForPeriod(periods.current, reservations);
  const nextPeriodRemaining = customer.minutesRemainingForPeriod(periods.next, reservations);

  return <Stack>

    <div>
      <Text>{periods.current.start.toFormat('LLL dd')} - {periods.current.end.minus({days: 1}).toFormat('LLL dd')}</Text>
      <Progress value={(currentPeriodRemaining / customer.monthlyMinutes) * 100} />
      <Text>{currentPeriodRemaining} Minutes remaining</Text>
    </div>
    <div>
      <Text>{periods.next.start.toFormat('LLL dd')} - {periods.next.end.minus({days: 1}).toFormat('LLL dd')}</Text>
      <Progress value={(nextPeriodRemaining / customer.monthlyMinutes) * 100} />
      <Text>{nextPeriodRemaining} Minutes remaining</Text>
    </div>
    {customer.credits > 0 && <div>
      <Text>+ {customer.credits} Minutes in credits</Text>
    </div>}
  </Stack>
}