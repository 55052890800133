import { useDocument } from "react-firebase-hooks/firestore"
import App from "../App"
import { Center, Loader } from "@mantine/core";
import { CustomerContext } from "../customerContext";
import { Customer } from "../shared/customer";
import SignUpFlow from "./SignUpFlow";
import GatewayHeader from "./GatewayWrapper";
import { useContext } from "react";
import { UserContext } from "./UserGateway";

export default function CustomerGateway() {

  const user = useContext(UserContext);

  const [customerSS, loading, _error] = useDocument(Customer.ref(user.uid));
  const customer = customerSS?.data();

  if (loading) return <GatewayHeader step={null}><Center><Loader /></Center></GatewayHeader>;

  if (!customer || !customer.isActive()) {
    return <GatewayHeader step={2}><SignUpFlow /></GatewayHeader>
  }

  return <CustomerContext.Provider value={customer} >
    <App />
  </CustomerContext.Provider>
}