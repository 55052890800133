
import { Location } from "./location";
import FirestoreModel from "../FirestoreModel";


export class Bay extends FirestoreModel<Bay>() {

  name: string;

  static collectionPath = (location: string) => [...Location.refPath(location), "bays"]

  constructor(snapshot: any) {
    super(snapshot);
    const data = snapshot.data();
    this.name = data.name;
    // const data = snapshot.data();
  }
}