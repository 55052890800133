import { useViewportSize } from "@mantine/hooks";
import { DateTime } from "luxon";
import { useContext, useEffect, useRef, useState } from "react";
import { Location } from "../shared/location"
import { Carousel } from "@mantine/carousel";
import { Card, Modal, Group, Stack, Button, Title, RingProgress, Tooltip } from "@mantine/core";
import ReserveModal from "./ReserveModal";
import { CustomerContext } from "../customerContext";
import { onSnapshot } from "firebase/firestore";
import { Slot } from "../shared/slot";
import { Bay } from "../shared/bay";

const SlotDetails = ({ time, bays, slotByBay }: { time: DateTime, bays: Bay[], slotByBay: {[bayId: string]: Slot} }) => {

  const [reservingTime, setReservingTime] = useState<DateTime | null>(null);
  const customer = useContext(CustomerContext);

  const timeAvailable = Location.timeAvailable(time, bays, slotByBay, customer);
  const baysAvailable = Location.baysAvailable(time, bays, slotByBay);

  return <>
    {reservingTime && <Modal opened={!!reservingTime} onClose={() => setReservingTime(null)} title="Make Reservation">
      <ReserveModal time={reservingTime} timeAvailable={timeAvailable} close={() => setReservingTime(null)} />
    </Modal>}
    <Group
      key={time.toString()}
      justify="space-between"
      pr="sm" pl="sm"
      styles={{ root: { borderBottom: "1px solid grey" } }}
      bg={timeAvailable ? "lightgreen" : "lightgrey"} >
      <Title pt="13px" pb="13px" w="60px" order={5} styles={{ root: { borderRight: "1px solid grey" } }}>{time.toFormat("HH:mm")}</Title>
      <Group>
        {timeAvailable &&
          <Tooltip label={`${baysAvailable} of ${bays.length} bays available`} events={{ hover: true, focus: true, touch: true }}>
            <RingProgress size={25} thickness={3} sections={[
              { value: (baysAvailable/bays.length)*100, color: "blue" }
            ]}/>
          </Tooltip>
        }
        <Button disabled={!timeAvailable} onClick={() => setReservingTime(time)}>Book</Button>
      </Group>
    </Group>
  </>

}

export default function ReserveSlide({ date, bays }: { date: DateTime<true>, bays: Bay[] }) {

  const [slotByBay, setSlotByBay] = useState<{[bayId: string]: Slot}>({});

  useEffect(() => {
    const unsubs: (() => void)[] = [];
    for (const bay of bays) {
      const unsub = onSnapshot(
        Slot.ref(date.toISODate(), Location.id, bay.id),
        slotSS => setSlotByBay(s => {
          const updatedSlot = slotSS.data();
          if(updatedSlot) {
            return { ...s, [bay.id]: updatedSlot };
          } else {
            return s;
          }
          
      }), console.log)

      unsubs.push(unsub);
    }
    return () => {
      unsubs.map(u => u());
    }
  }, [date, bays])

  const scrollRef = useRef<HTMLTableSectionElement>(null);
  const { height } = useViewportSize();

  useEffect(() => {
    if (!scrollRef.current) return;
    const oldRef = scrollRef.current;

    const thisListener = () => {
      const detail = { top: scrollRef.current!.scrollTop, e: scrollRef.current };
      window.dispatchEvent(new CustomEvent("SlideScroll", { detail }))
    }

    scrollRef.current.addEventListener("scroll", thisListener);

    const othersListener = ((e: CustomEvent) => {
      if (e.detail.e === scrollRef.current) return;
      scrollRef.current?.scroll({ top: e.detail.top })
    }) as EventListener;

    window.addEventListener("SlideScroll", othersListener)

    return () => {
      oldRef.removeEventListener("scroll", thisListener);
      window.removeEventListener("SlideScroll", othersListener)
    }
  }, []);

  const slotsDetails = [];

  for (let i = Location.openingMinute; i < Location.closingMinute; i += 30) {
    const time = date.plus({ minutes: i });
    slotsDetails.push(<SlotDetails key={time.toSeconds()} time={time} bays={bays} slotByBay={slotByBay} />)
  }

  return <Carousel.Slide key={date.toSeconds()}>
    <Card shadow="sm" radius="md" withBorder p="md">
      <Card.Section>
        <Group justify="space-between" p="sm" bg="rgba(34, 139, 230, 0.1)">
          <Title order={3}>{date.toFormat('EEE LLL dd')}</Title>
        </Group>
        <Stack ref={scrollRef} style={{ overflow: "scroll", height: height - 230 }} gap={0}>
          {slotsDetails}
        </Stack>
      </Card.Section>
    </Card>

  </Carousel.Slide>;
}